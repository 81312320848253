<template>
	<div v-if="dialogVisible">
		<el-dialog title="上报详情" :visible.sync="dialogVisible" width="50%">			
			<div class="addbox" style="display: flex;flex-direction: column;justify-content: space-between;">
				<div class="infobox">
					<div class="left_info">
						
						<div class="item_line">
							<span class="tlename">履职活动类型：</span>
							<span class="valuename">{{detail.classify.name}}</span>
						</div>
						<div class="item_line">
							<span class="tlename">活动开始时间：</span>
							<span class="valuename">{{detail.start_time}}</span>
						</div>
						<div class="item_line">
							<span class="tlename">活动名称：</span>
							<span class="valuename">{{detail.name}}</span>
						</div>
						<div class="item_line">
							<span class="tlename">履职内容：</span>
							<span class="valuename">{{detail.content}}</span>
						</div>
						<div class="item_line">
							<span class="tlename">提交人：</span>
							<span class="valuename">{{detail.user[0].name}}</span>
						</div>
						<div class="item_line">
							<span class="tlename">图片列表：</span>
							<div class="valuename">
								<el-image v-for="(item,index) in detail.images"
								    style="width: 100px; height: 100px"
								    :src="item.fullurl" 
								    :preview-src-list="detail.images.map((item)=>{return item.fullurl})">
								</el-image>
							</div>
							
						</div>
						<div class="item_line">
							<span class="tlename">附件列表：</span>
							<div class="valuename">
								<div v-for="(item,index) in detail.files" @click="openurl(item.fullurl)" style="cursor: pointer;color: #409EFF;">
									{{item.filename}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="btnbox">					
					<el-button type="primary" size="mini" class="font14" @click="dialogVisible=false">关闭</el-button>
				</div>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		props:{
			istz:{
				type:Boolean,
				default:false
			},
			updataQx:{
				type:Number,
				default:1
			}
		},
		data() {
			return {
				dialogVisible: false,
				detail:''
			}
		},
		created() {
			
		},
		methods: {
			openurl(url){
				window.open(url,'_blank')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.valuename{
		width: 80%;
	}
	.tapmenu{
		font-size: 16px;
		margin-right:30px;
		cursor: pointer;
		padding-bottom: 5px;
	}
	.active_tap{
		color: #409EFF;	
		border-bottom: 2px solid #409EFF;
	}
	.item_line{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		
		.tlename{
			width: 100px;
			font-size: 14px;
			text-align: right;
			color: #999;
		}
		.bticon{
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}
	.infobox{
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;
		.contbox{
			display: flex;
			padding:20px 40px;
			height: 750px;
			transition: all 0.5s; 
			overflow: hidden;
		}
		.colosecont{
			height: 0;
			padding: 0;
		}
		.title{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}
	.addbox {
		max-height: 85vh;
		height: auto;
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
		
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
		border-top: 1px solid #eee;
	}

	::v-deep .el-form-item__error{
		padding-top: 0;
	}
	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: '' !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}
	::v-deep .myinputicon{
		cursor: pointer;
	}
	::v-deep .myinputicon:hover{
		color: red !important;
	}
	::v-deep .el-dialog__wrapper{
		z-index: 2000 !important;
	}
</style>